<template>
  <div class="outerContainer">
    <ul class="c-sidebar-nav h-100 innerContainer">
      <template v-for="(item, index) in nav[0]._children">
        <template v-if="checkPermission(item.permission)">
          <li class="c-sidebar-nav-item" v-if="item._name == 'CSidebarNavItem'" v-bind:key="index">
            <router-link :to="item.to" class="c-sidebar-nav-link">
              <b-icon :icon="item.icon"  class="c-sidebar-nav-icon"/>{{item.name}}
            </router-link>
          </li>
          <li class="c-sidebar-nav-title" v-else-if="item._name == 'CSidebarNavTitle'" v-bind:key="index">
            {{item._children[0]}}
          </li>
          <li class="c-sidebar-nav-dropdown" 
              v-bind:class="{'c-show': dropdownController[index]}" 
              v-else-if="item._name == 'CSidebarNavDropdown'" 
              v-bind:key="index">
            <a class="c-sidebar-nav-dropdown-toggle" @click="toggle(index)">
              <b-icon :icon="item.icon"  class="c-sidebar-nav-icon"/>{{item.name}}
            </a>
            <ul class="c-sidebar-nav-dropdown-items">
              <template v-for="(subItem, subIndex) in item.items">
                <template v-if="subItem.permission == null || checkPermission(subItem.permission)">
                  <li class="c-sidebar-nav-item" v-bind:key="subIndex">
                    <router-link :to="subItem.to" class="c-sidebar-nav-link">
                      <b-icon :icon="subItem.icon" class="c-sidebar-nav-icon"/>{{subItem.name}}
                    </router-link>
                  </li>
                </template>
              </template>
            </ul>
          </li>
          <li v-else-if="item._name == 'CSidebarNavDivider'" class="mt-2" v-bind:key="index"></li>
        </template>
      </template>
    </ul>
  </div>
</template>
<script>
import nav from './_nav'
import userService from '@/services/userService'

export default {
  name: 'MenuItems',
  data () {
    return {
      minimize: false,
      nav,
      show: 'responsive',
      dropdownController: [],
      showToken: false
    }
  },
  created () {
    this.init()
  },
  methods: {
    init() {
      for(var i = this.nav[0]._children.length - 1; i>=0 ;i --) {
        this.dropdownController[i] = false
      }
    },
    toggle(index) {
      this.$set(this.dropdownController, index, !this.dropdownController[index])
    },
    checkPermission(permission) {
      if(permission) {
        return userService.allow(permission)
      } else {
        return true
      }
    }
  }
}
</script>
<style scoped>
.outerContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.innerContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: -17px; /* Increase/Decrease this value for cross-browser compatibility */
  overflow-y: scroll;
}

</style>