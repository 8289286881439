<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
          Hi, {{ username }}
      </CHeaderNavLink>
    </template>
    <CDropdownItem v-on:click="logout">
      <b-icon icon="box-arrow-left" class="c-sidebar-nav-icon"/>
      <div style="display:inline;">Logout</div>
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import { INACTIVE_USER_TIME_THRESHOLD, USER_ACTIVITY_THROTTLER_TIME, REFRESH_TOKEN_TIME_THRESHOLD } from "@/helpers/constants"
import userService from '@/services/userService'

export default {
  name: 'HeaderAccount',
  data () {
    return { 
      username: '',
      userActivityThrottlerTimeout: null,
      userActivityTimeout: null
    }
  },
  created() {
    this.fetchData()
  },
  beforeMount() {
    this.activateActivityTracker();
  },

  beforeDestroy() {
    this.deactivateActivityTracker();
    clearTimeout(this.userActivityTimeout);
    clearTimeout(this.userActivityThrottlerTimeout);
  },
  methods: {
    logout() {
      sessionStorage.clear()
      location.reload(true)
    },
    fetchData() {
      this.username = sessionStorage.name

      this.interval = setInterval(() => this.refreshToken(), REFRESH_TOKEN_TIME_THRESHOLD);
    },
    activateActivityTracker() {
      window.addEventListener("mousemove", this.userActivityThrottler)
      window.addEventListener("scroll", this.userActivityThrottler)
      window.addEventListener("keydown", this.userActivityThrottler)
      window.addEventListener("resize", this.userActivityThrottler)
    },
    deactivateActivityTracker() {
      window.removeEventListener("mousemove", this.userActivityThrottler)
      window.removeEventListener("scroll", this.userActivityThrottler)
      window.removeEventListener("keydown", this.userActivityThrottler)
      window.removeEventListener("resize", this.userActivityThrottler)
    },
    resetUserActivityTimeout() {
      clearTimeout(this.userActivityTimeout)

      this.userActivityTimeout = setTimeout(() => {
        this.userActivityThrottler()
        this.inactiveUserAction()
      }, INACTIVE_USER_TIME_THRESHOLD)
    },
    userActivityThrottler() {

      if (!this.userActivityThrottlerTimeout) {
        this.userActivityThrottlerTimeout = setTimeout(() => {
          this.resetUserActivityTimeout()
          clearTimeout(this.userActivityThrottlerTimeout)
          this.userActivityThrottlerTimeout = null
        }, USER_ACTIVITY_THROTTLER_TIME)
      }
    },
    inactiveUserAction() {
      //logout user here
      this.logout()
    },
    refreshToken() {
      userService.refreshToken()
        .then(result => {
          sessionStorage.token = result.token
        })
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>