import apiHandler from '@/helpers/apiHandler'

const userService = {
  login,
  logout,
  getAll,
  create,
  find,
  newUser,
  suspend,
  remove,
  reactivate,
  update,
  allow,
  updateMyProfile,
  me,
  findCompanyName,
  firstLogin,
  refreshToken,
  getPgpKey,
  ssoLogin
}

function login (username, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password })
  }

  return fetch(apiHandler.host() + 'portal-auth-api/authenticate', requestOptions)
    .then(apiHandler.handleResponse)
    .then(user => {
      // login successful if there's a jwt token in the response
      if (user.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        initSession(user)
      }

      return user
    })
}

function ssoLogin (idToken, accessToken) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id_token: idToken, access_token:accessToken })
  }

  return fetch(apiHandler.host() + 'portal-auth-api/authenticate', requestOptions)
    .then(apiHandler.handleResponse)
    .then(user => {
      // login successful if there's a jwt token in the response
      if (user.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        initSession(user)
      }

      return user
    })
}

function initSession (user) {
  sessionStorage.setItem('user', JSON.stringify(user))

  for (var permission of user.permissions) {
    sessionStorage[permission] = true
  }

  sessionStorage.username = user.username
  sessionStorage.name = user.display_name
  sessionStorage.token = user.token
  sessionStorage.path = user.path
}

function can (permission) {
  return sessionStorage[permission]
}

function allow (permission) {
  if (permission == null) {
    return true
  } else if (Array.isArray(permission)) {
    for (var i = permission.length - 1; i >= 0; i--) {
      if (can(permission[i])) {
        return true
      }
    }
    return false
  } else if (can(permission)) {
    return true
  } else {
    return false
  }
}
function getAll () {
  return apiHandler.commonAPICall('users', '')
}

function logout () {
  // remove user from local storage to log user out
  localStorage.clear()
}

function find (id) {
  return callUserApi('find', id)
}

function me () {
  return apiHandler.commonAPICall('user/me', '')
}

function getPgpKey (uuid) {
  const requestBody = JSON.stringify({
    uuid
  })
  return apiHandler.commonAPICall('user/pgp-key', requestBody)
}

function create (model) {
  model = setPhone(model)
  const requestBody = JSON.stringify({
    user: model,
    new_password: model.password,
    confirmed_password: model.confirmed_password,
    pgp_key: model.pgp_key
  })

  return apiHandler.commonAPICall('user/add', requestBody)
}

function newUser () {
  return {
    uuid: '',
    username: '',
    display_name: '',
    email: '',
    role_uuid: '',
    password: '',
    phone: '',
    phoneNo1: '+60',
    phoneNo2: '',
    confirmed_password: '',
    company_id: null,
    user_type: '',
    role: {
      uuid: ''
    },
    pgp_key: '',
    pgp_fingerprint: ''
  }
}

function updateMyProfile (model) {
  model = setPhone(model)
  const requestBody = JSON.stringify({
    user: model,
    new_password: model.password,
    confirmed_password: model.confirmed_password,
    pgp_key: model.pgp_key
  })

  return apiHandler.commonAPICall('user/update-my-profile', requestBody)
}

function update (model) {
  model = setPhone(model)
  const requestBody = JSON.stringify({
    user: model,
    new_password: model.password,
    confirmed_password: model.confirmed_password,
    pgp_key: model.pgp_key
  })

  return apiHandler.commonAPICall('user/update', requestBody)
}

function suspend (id) {
  return callUserApi('suspend', id)
}

function reactivate (id) {
  return callUserApi('reactivate', id)
}

function remove (id) {
  return callUserApi('delete', id)
}

function callUserApi (path, uuid) {
  const requestBody = JSON.stringify({
    uuid
  })

  return apiHandler.commonAPICall('user/' + path, requestBody)
}

function findCompanyName (type, id) {
  const requestBody = JSON.stringify({
    requestor_id: id,
    type
  })
  return apiHandler.commonAPICall('user/company-name', requestBody)
}

function firstLogin (new_password, confirmed_password) {
  const requestBody = JSON.stringify({
    new_password,
    confirmed_password
  })
  return apiHandler.commonAPICall('first-login', requestBody)
}

function refreshToken () {
  const requestOptions = {
    headers: apiHandler.authHeader(),
    method: 'POST'
  }

  return fetch(
    apiHandler.host() + 'portal-auth-api/refresh-token',
    requestOptions
  ).then(apiHandler.handleResponse)
    .catch(apiHandler.handleException)
}

function setPhone (model) {
  model.phone = model.phoneNo1 + model.phoneNo2
  return model
}

export default userService
