<template>
  <div class="c-app">
    <SrcSidebar/>
    <div class="c-wrapper">
      <SrcHeader/>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade">
              <router-view></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <CNotification />
      <SrcFooter/>
    </div>
  </div>
</template>

<script>
import SrcSidebar from './SrcSidebar'
import SrcHeader from './SrcHeader'
import SrcFooter from './SrcFooter'
import { CNotification } from '@/views/components/index.js'

export default {
  components: {
    SrcSidebar,
    SrcHeader,
    SrcFooter,
    CNotification
  },
  computed: {
    first() {
      const first = sessionStorage.reset_password
      if(first) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
