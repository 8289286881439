export const INACTIVE_USER_TIME_THRESHOLD = 1800000 // perform action when user inactive for this amount of time
export const REFRESH_TOKEN_TIME_THRESHOLD = 3300000 // refresh token every x miliseconds
export const USER_ACTIVITY_THROTTLER_TIME = 5000 // throttle time to re-call action listener

export const Status = Object.freeze({
  ACTIVE: '00',
  SUSPENDED: '01',
  DELETED: '02',
  EXPIRED: '03'
})

export const ConsumerStatus = Object.freeze({
  ACTIVE: '00',
  SUSPENDED: '01',
  DELETED: '02',
  LOCKED: '03'
})

export const RequestorType = Object.freeze({
  MERCHANT: '00',
  ISSUER: '01',
  DS: '02',
  SWITCH: '03',
  PORTAL: '04'
})

export const TokenRequestorType = Object.freeze({
  PAYMENT_GATEWAY: '00',
  MERCHANT: '01',
  WALLET_PROVIDER: '02'
})

export const TaskStatus = Object.freeze({
  PENDING: '00',
  APPROVED: '01',
  REJECTED: '02',
  WITHDRAWED: '03'
})

export const HostType = Object.freeze({
  SWITCH: '00',
  DS: '01'
})

export const UserType = Object.freeze({
  INTERNAL: '00',
  ISSUER: '01',
  MERCHANT: '02'
})

export const Portal = Object.freeze({
  INTERNAL: 'portal-api',
  ISSUER: 'issuer-portal-api',
  MERCHANT: 'srci-portal-api'
})

export const MerchantSetting = Object.freeze({
  NOTIFICATION_URL: 'NOTIFICATION_URL'
})

export const IssuerSetting = Object.freeze({
  NOTIFICATION_URL: 'NOTIFICATION_URL',
  SUPPORT_CARD_VERIFICATION: 'SUPPORT_CARD_VERIFICATION'
})

export const IdentityType = Object.freeze({
  EMAIL_ADDRESS: '00',
  MOBILE_NUMBER: '01'
})
