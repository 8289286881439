<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      v-c-emit-root-event:toggle-sidebar-mobile
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      v-c-emit-root-event:toggle-sidebar
    />
    <CHeaderBrand
      class="mx-auto d-lg-none" 
      src="/img/header-mydebit-logo.svg"
      width="190"
      height="46"
      alt="CoreUI Logo"
    />
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/">
          {{ systemName }}
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <HeaderAccount/>
    </CHeaderNav>
    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0"/>
    </CSubheader>
  </CHeader>
</template>

<script>
import HeaderAccount from './HeaderAccount'
import { Portal } from '@/helpers/constants'

export default {
  components: {
    HeaderAccount
  },
  computed: {
    systemName () {
      if(sessionStorage.path == Portal.INTERNAL) {
        return "Admin Console"
      } else if(sessionStorage.path == Portal.ISSUER) {
        return "Issuer Portal"
      } else if(sessionStorage.path == Portal.MERCHANT) {
        return "SRCI Portal"
      } else {
        return ""
      }
    }
  }
}
</script>
