export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/',
        icon: 'house-door'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Module'],
        permission: ['checkout_view', 'checkout_event_view', 'profile_view', 'client_view', 'client_profile_view', 'dcf_view', 'bin_view', 'merchant_view', 'dpa_view', 'data_export']
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Checkout Management',
        icon: 'cart-check',
        route: '/checkouts',
        permission: ['checkout_view', 'checkout_event_view'],
        items: [
          {
            name: 'Checkouts',
            to: '/checkouts',
            icon: 'cart-check',
            permission: 'checkout_view'
          },
          {
            name: 'Checkout Events',
            to: '/checkouts/events',
            icon: 'card-list',
            permission: 'checkout_event_view'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Profile Management',
        icon: 'folder2-open',
        permission: 'profile_view',
        items: [
          {
            name: 'Consumer Profiles',
            to: '/profiles',
            icon: 'person-square',
            permission: 'profile_view'
          },
          {
            name: 'Payment Cards',
            to: '/cards',
            icon: 'credit-card2-front',
            permission: 'profile_view'
          },
          {
            name: 'Trusted Devices',
            to: '/devices',
            icon: 'phone',
            permission: 'profile_view'
          },
          {
            name: 'Addresses',
            to: '/addresses',
            icon: 'geo-alt',
            permission: 'profile_view'
          },
          {
            name: 'Card Events',
            to: '/cards/events',
            icon: 'card-list',
            permission: 'profile_view'
          }
        ]
      },
      {
        _name: 'CSidebarNavItem',
        name: 'SRC Client Management',
        to: '/clients',
        permission: 'client_view',
        icon: 'terminal'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'SRC Client Profile',
        to: '/client/me',
        permission: 'client_profile_view',
        icon: 'terminal'
      },
      // {
      //   _name: 'CSidebarNavDropdown',
      //   name: 'Card Issuer Management',
      //   icon: 'building',
      //   route: '/issuers',
      //   permission: ['issuer_view', 'issuer_profile_view'],
      //   items: [
      //     {
      //       name: 'Card Issuers',
      //       to: '/issuers',
      //       icon: 'building',
      //       permission: 'issuer_view'
      //     },
      //     {
      //       name: 'Card Issuer Detail',
      //       to: '/issuer/profile',
      //       icon: 'building',
      //       permission: 'issuer_profile_view'
      //     }
      //   ]
      // },
      {
        _name: 'CSidebarNavItem',
        name: 'DCF Management',
        to: '/dcfs',
        permission: 'dcf_view',
        icon: 'server'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Card BIN Management',
        to: '/bins',
        permission: 'bin_view',
        icon: 'credit-card'
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Merchant Management',
        icon: 'cart',
        route: '/merchants',
        permission: ['merchant_view', 'dpa_view'],
        items: [
          {
            name: 'Merchants',
            to: '/merchants',
            icon: 'cart',
            permission: 'merchant_view'
          },
          {
            name: 'DPAs',
            to: '/dpas',
            icon: 'window',
            permission: 'dpa_view'
          }
        ]
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Export Data',
        to: '/data',
        permission: 'data_export',
        icon: 'table'
      },
      {
        _name: 'CSidebarNavDivider',
        _class: 'm-2'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['System'],
        permission: ['system_view', 'legal_view', 'access_view', 'system_infra', 'audit_view', 'task_history_view']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'General Settings',
        to: '/settings',
        permission: 'system_view',
        icon: 'gear'
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Legal Settings',
        route: '/users',
        icon: 'files',
        permission: 'legal_view',
        items: [
          {
            name: 'Legal Consents',
            to: '/consents',
            icon: 'ui-checks'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Access Control',
        route: '/users',
        icon: 'people',
        permission: 'access_view',
        items: [
          {
            name: 'Users',
            to: '/users',
            icon: 'person'
          },
          {
            name: 'Roles',
            to: '/roles',
            icon: 'person-check'
          }
        ]
      },
      {
        _name: 'CSidebarNavItem',
        name: 'System Infrastructure',
        to: '/system-infra',
        permission: 'system_infra',
        icon: 'gear'
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Logs',
        icon: 'table',
        permission: ['audit_view', 'task_history_view'],
        items: [
          {
            name: 'Audit Logs',
            to: '/audit-logs',
            permission: 'audit_view',
            icon: 'table'
          },
          {
            name: 'Task History',
            to: '/task-history',
            permission: 'task_history_view',
            icon: 'table'
          }
        ]
      },
      {
        _name: 'CSidebarNavDivider',
        _class: 'm-2'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['My Profile']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Account Detail',
        to: '/me',
        icon: 'person'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Logout',
        to: '/logout',
        icon: 'box-arrow-left'
      }
    ]
  }
]
