<template>
  <CSidebar 
    fixed 
    :minimize="minimize"
    :show.sync="show"
  >
    <div class="c-sidebar-brand ml-3">
      <router-link to="/">
        <img src="/img/header-mydebit-logo.svg" width="160" height="50" alt="Logo" />
      </router-link>
    </div>
    <MenuItems/>
    
  </CSidebar>
</template>

<script>
import nav from './_nav'
import MenuItems from './MenuItems'

export default {
  components: {MenuItems},
  data () {
    return {
      minimize: false,
      nav,
      show: 'responsive'
    }
  },
  mounted () {
    this.$root.$on('toggle-sidebar', () => {
      const sidebarOpened = this.show === true || this.show === 'responsive'
      this.show = sidebarOpened ? false : 'responsive'
    })
    this.$root.$on('toggle-sidebar-mobile', () => {
      const sidebarClosed = this.show === 'responsive' || this.show === false
      this.show = sidebarClosed ? true : 'responsive'
    })
  }
}
</script>
