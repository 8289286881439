import userService from '@/services/userService'
import router from '@/router/index'

const user = JSON.parse(sessionStorage.getItem('user'))
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: {}, user: null }

const authentication = {
  namespaced: true,
  state: initialState,
  actions: {
    login ({ dispatch, commit }, { username, password, redirect }) {
      commit('loginRequest', { username })

      userService.login(username, password)
        .then(r => {
          if (r.status == 200) {
            commit('loginSuccess', r)
            router.push(redirect)
          } else {
            commit('loginFailure')
            dispatch('alert/error', r.message)
          }
        })
    },
    logout ({ commit }) {
      userService.logout()
      commit('logout')
    }
  },
  mutations: {
    loginRequest (state, user) {
      state.status = { loggingIn: true }
      state.user = user
    },
    loginSuccess (state, user) {
      state.status = { loggedIn: true }
      state.user = user
    },
    loginFailure (state) {
      state.status = {}
      state.user = null
    },
    logout (state) {
      state.status = {}
      state.user = null
    }
  }
}

export default authentication
